import React, { useState } from 'react';
import Navbar from '../../Navbar';
import './style.scss';
import ReactTooltip from 'react-tooltip';
import StartModal from '../../../trivia/StartModal';

const Index = () => {
    const [price, setPrice] = useState(1);
    const [show, setShow] = useState(false);

    return (
        <div className='ewPricingHeaderWrap'>
            <div className='engageContainer'>
                <Navbar />
                <div className='ewPricingHeader'>
                    <div className='ewPricingHeading'>
                        <h1>Build team culture for less than the cost of a cup of coffee</h1>
                    </div>
                    <div className='ewPricingDetails'>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/pricing/secure.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/pricing/secure.svg'}
                                alt={'stripe'}
                            />
                            Secured with Stripe
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/pricing/trial.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/pricing/trial.svg'}
                                alt={'free trial'}
                            />
                            90-day free trial
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/pricing/assited.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/pricing/assited.svg'}
                                alt={'assisted '}
                            />
                            Assisted Onboarding
                        </span>
                    </div>

                    <div className='ewPricingWrap'>
                        <div className='ewPricingCard'>
                            <div className='ewPackage'>
                                <h2>Free</h2>
                                <p>Upto 50 users</p>
                                <h6>Basic</h6>
                            </div>
                            <div className='ewPricingCheckList'>
                                <span>Unlimited Kudos</span>
                                <span>Unlimited Shoutouts</span>
                                <span>Pulse Surveys</span>
                                <span>Organization Leaderboards</span>
                                <span>Highlight Organization Values</span>
                                <span>
                                    Redeemable Points
                                    <img
                                        className='infoIcon'
                                        src={'https://sw-assets.gumlet.io/engagewith/pricing/info-icon.svg'}
                                        data-src={'https://sw-assets.gumlet.io/engagewith/pricing/info-icon.svg'}
                                        alt=''
                                    />
                                    <div className='infoToolTipWrap'>
                                        <div className='infoToolTip'>
                                            Requires Credit Card
                                            <img
                                                src={'https://sw-assets.gumlet.io/engagewith/pricing/arrow.svg'}
                                                data-src={'https://sw-assets.gumlet.io/engagewith/pricing/arrow.svg'}
                                                alt=''
                                            />
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <button onClick={() => setShow(true)}>Add EngageWith </button>
                        </div>

                        {/* Pro */}
                        <div className='ewPricingCard ewPricingCardPro'>
                            <div className='ewPackage'>
                                <h3>
                                    $ {price * 2}
                                    <span>/{price === 1 ? ' user / ' : null} month</span>
                                </h3>
                                <p>{price !== 1 ? <span>For {price} users</span> : <span>Up to 499 users</span>}</p>
                                <h6>Pro</h6>
                            </div>

                            <div className='valueSlider'>
                                <div className='slidecontainer pricing-notify d-flex justify-content-between'>
                                    <h6 className='ml-2'>1</h6>
                                    <p>no of users</p>
                                    <h6>499</h6>
                                </div>
                                <div className='slidecontainer'>
                                    <div className='sliderViewer'>
                                        <input
                                            type='range'
                                            value={price}
                                            onChange={(e) => setPrice(Number(e.target.value))}
                                            min='1'
                                            data-tip=''
                                            data-for='copy'
                                            max='499'
                                            className='slider'
                                            id='myRange'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='ewPricingCheckList'>
                                <span>Unlimited Kudos</span>
                                <span>Unlimited Shoutouts</span>
                                <span>Pulse Surveys</span>
                                <span>Organization Leaderboards</span>
                                <span>Highlight Organization Values</span>
                                <span>
                                    Redeemable Points
                                    <img
                                        className='infoIcon'
                                        src={'https://sw-assets.gumlet.io/engagewith/pricing/info-icon.svg'}
                                        data-src={'https://sw-assets.gumlet.io/engagewith/pricing/info-icon.svg'}
                                        alt=''
                                    />
                                    <div className='infoToolTipWrap'>
                                        <div className='infoToolTip'>
                                            Requires Credit Card
                                            <img
                                                src={'https://sw-assets.gumlet.io/engagewith/pricing/arrow.svg'}
                                                data-src={'https://sw-assets.gumlet.io/engagewith/pricing/arrow.svg'}
                                                alt=''
                                            />
                                        </div>
                                    </div>
                                </span>
                                <span>Priority Support </span>
                            </div>
                            <button onClick={() => setShow(true)}>Add EngageWith </button>
                        </div>

                        {/* Contact Us */}
                        <div className='ewPricingCard ewPricingCardContact'>
                            <div className='ewPackage'>
                                <h2>Contact Us</h2>
                                <p>More than 500 users</p>
                                <h6>Enterprise</h6>
                            </div>

                            <div className='ewPricingCheckList'>
                                <h6>Includes everything in Pro+</h6>
                                <span>Performance Reports</span>
                                <span>Dedicated Customer Support</span>
                                <span>Dedicated Account Manager</span>
                                <span>Customizations</span>
                                <span>99% Uptime SLA</span>
                            </div>
                            <button onClick={() => window.open('https://calendly.com/engagewith-demo/demo', '_blank')}>
                                Contact Us{' '}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <StartModal
                login={false}
                show={show}
                hide={() => setShow(false)}
                engageWith={true}
                slackAddUrl={process.env.GATSBY_SE_SLACK_URL}
                teamsAddUrl={process.env.GATSBY_SE_MS_TEAMS_URL}
            />

            <ReactTooltip id='copy' place='top' type='light' effect='float'>
                {price}
            </ReactTooltip>
        </div>
    );
};

export default Index;
