import React, { useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import './style.scss';

const Pricing = () => {
    const [open, setOpen] = useState(undefined);
    const faqs = [
        {
            question: 'What is the forever free plan?',
            answer:
                'In this plan, you’ll be able to access all the features in EngageWith. No holds. No bars. (even if you’re using a free Slack plan). Kindly note, The maximum number of users allowed in this plan is 50.',
        },
        {
            question: 'How do you calculate the number of clients?',
            answer: 'It’s based on the number of clients you add to EngageWith.',
        },
        {
            question: 'Where can I redeem my points?',
            answer:
                'EngageWith is integrated with platforms like Amazon, Flipkart, Ola, Uber. The Kudos and Shoutouts you receive gets converted into points for you to redeem on platforms you prefer. ',
        },
        {
            question: 'Does it have a cancellation fee?',
            answer:
                'No. EngageWith is a pay-as-you-go service. You can cancel whenever you want but your cancellation will come into effect only after the end of your on-going term.',
        },
        {
            question: 'Can I avail annual plans?',
            answer:
                'Yes, you can. By opting for annual plans you’re eligible for exciting offers and discounts. Please contact our support team to get started right away.',
        },
        {
            question: 'Is my data safe?',
            answer:
                'We are committed to maintaining strong data protection commitments. Rightly so we are certified by ISO and PCI. Our application endpoints are TLS/SSL/DCMA/SMAL placing us right amongst the industry pioneers.',
        },
    ];
    return (
        <div className='ewFAQWrap'>
            <div className='engageContainer'>
                <div className='ewFAQHeader'>
                    <h3>FAQ</h3>
                </div>

                <Accordion className='ewFAQAccordion'>
                    {faqs.map((faq, i) => (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    onClick={() => setOpen(open === i ? undefined : i)}
                                    as={Button}
                                    variant='link'
                                    eventKey={i + 1}
                                >
                                    <span>{faq.question}</span>
                                    <span>
                                        <img
                                            src={
                                                open === i
                                                    ? 'https://sw-assets.gumlet.io/engagewith/pricing/Sub.svg'
                                                    : 'https://sw-assets.gumlet.io/engagewith/pricing/plus.svg'
                                            }
                                            data-src={
                                                open === i
                                                    ? 'https://sw-assets.gumlet.io/engagewith/pricing/Sub.svg'
                                                    : 'https://sw-assets.gumlet.io/engagewith/pricing/plus.svg'
                                            }
                                            alt={''}
                                        />
                                    </span>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={i + 1}>
                                <Card.Body>{faq.answer}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </div>
        </div>
    );
};

export default Pricing;
